import React from "react"
import { createTextAreaToCopy } from "../../../components/Script/script"

import { BoxShadowWindow, Button } from "../../../StyleComponents/styles"
import { TextArtTextArea } from "../../../StyleComponents/pagesStyle"

const TextArtList = ({ pageItems }) => {
  function long_string(arr) {
    let longest = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].length > longest.length) {
        longest = arr[i]
      }
    }
    return longest
  }

  const copyTextArt = (e, art) => {
    createTextAreaToCopy(art)
    let button = e.target
    button.textContent = "Copied 👍"
    setTimeout(function () {
      button.textContent = "Copy"
    }, 1500)
  }

  return (
    <BoxShadowWindow>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          placeItems: "flex-start",
        }}
      >
        {pageItems.map((a, index) => {
          let column = a.split("\n")
          return (
            <div key={`a.name-${index}`} className="p-2">
              <TextArtTextArea
                type="text"
                aria-label={`textArt-${index}`}
                value={a}
                rows={a.split("\n").length}
                cols={long_string(column).length + 15}
                readOnly
              />
              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={e => copyTextArt(e, a)}
              >
                Copy
              </Button>
            </div>
          )
        })}
      </div>
    </BoxShadowWindow>
  )
}
export default React.memo(TextArtList)
