import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
// import Popup from "../../componentForPages/Popup/Popup"

import { Title } from "../../StyleComponents/styles"
import TextArtList from "./components/renderTextArtList"

const Pagination = React.lazy(() =>
  import("../../components/Pagination/Pagination")
)

const mavRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "✎ Text Art", link: "/text-art/" },
]
const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/emoji-copy-and-paste",
]

function EmojiNamesList(props) {
  const { currentPage, totalPages, pageItems } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/text-art/"
      : `/text-art/${(currentPage - 1).toString()}/`
  const nextPage = `/text-art/${(currentPage + 1).toString()}/`

  return (
    <Layout location={props.location}>
      <SEO
        title="Text Art ✂️ Copy And Paste"
        description="Text Art ✂️, copy and paste text art design like images, text Characters art images."
        keywords={[
          "text art, text image,ascii characters,ascii copy paste,ascii art copy paste, text characters, text",
        ]}
      />

      <NavBar listPages={mavRoadArray} />
      <section className="container">
        <Title id="listOfEmoji">Text Art Copy And Paste</Title>
        {/* {show && (
          <Popup
            title="Text Art"
            emoji="text art have been copied"
            close={() => setShow(false)}
          />
        )} */}
        <br />
        <TextArtList pageItems={pageItems} />
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              pagesLink="text-art"
            />
          </React.Suspense>
        )}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </section>
    </Layout>
  )
}

export default EmojiNamesList
